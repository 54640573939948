import type { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";

import ExteriorPoints from "./ExteriorPoints.vue";

export const UTILS_EXTERIOR_POINTS: RouteRecordRaw = {
  path: ROUTE_PATHS.UTILS_EXTERIOR_POINTS,
  component: ExteriorPoints,
  name: "Утилиты - Точки",
  meta: {
    getBreadcrumbs: (route, { t }) => [getLinks(t).HOME],
  },
};
