<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <template v-if="generation" #topRight>
      <ul class="breadcrumbs">
        <li v-for="item in breadcrumbs" :key="item.url">
          <RouterLink :to="item.url">{{ item.label }}</RouterLink>
        </li>
      </ul>
    </template>
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FormField
        v-model:value="values.name"
        name="name"
        :error="errors.name"
        label="Название"
      />

      <div class="row-cols-3">
        <FormFieldSelect
          v-model:value="values.type"
          :options="EngineTypeOptions"
          name="type"
          :error="errors.type"
          label="Тип"
        />
        <FormFieldSelect
          v-model:value="values.wheel"
          :options="WheelOptions"
          name="wheel"
          :error="errors.wheel"
          label="Привод"
        />
        <FormFieldSelect
          v-model:value="values.gearbox"
          :options="GearboxOptions"
          name="gearbox"
          :error="errors.gearbox"
          label="Коробка"
        />
      </div>

      <div class="row-cols-3">
        <FormField
          v-model:value="values.volume"
          name="volume"
          type="number"
          :error="errors.volume"
          :label="
            isElectric ? 'Аккумуляторная батарея, кВт/ч' : 'Объём двигателя, л.'
          "
        />
        <FormField
          v-model:value="values.power"
          name="power"
          type="number"
          :error="errors.power"
          label="Мощность двигателя, л.c."
        />

        <FormField
          v-model:value="values.gearsCount"
          name="gearsCount"
          type="number"
          :error="errors.gearsCount"
          label="Количество передач"
        />
      </div>

      <div class="row-cols-3">
        <FormField
          v-model:value="values.maxTorque"
          name="maxTorque"
          type="number"
          :error="errors.maxTorque"
          label="Макс. крутящий момент"
        />

        <FormField
          v-model:value="values.maxSpeed"
          name="maxSpeed"
          type="number"
          :error="errors.maxSpeed"
          label="Максимальная скорость, км/ч."
        />

        <FormField
          v-model:value="values.acceleration0_100"
          name="acceleration0_100"
          type="number"
          :error="errors.acceleration0_100"
          label="Время разгона до 100 км/ч, сек."
        />
      </div>

      <div class="row-cols-3">
        <FormField
          v-model:value="values.consumptionCity"
          name="consumptionCity"
          type="number"
          :error="errors.consumptionCity"
          label="Расход, город, л."
        />
        <FormField
          v-model:value="values.consumptionHighway"
          name="consumptionHigway"
          type="number"
          :error="errors.consumptionHigway"
          label="Расход, трасса, л."
        />
        <FormField
          v-model:value="values.consumptionMixed"
          name="consumptionMixed"
          type="number"
          :error="errors.consumptionMixed"
          label="Расход, смешанный, л."
        />
      </div>

      <div class="row-cols-4">
        <FormFieldSelect
          v-model:value="values.euroEmissionsStandard"
          :options="EuroStandardOptions"
          name="euroEmissionsStandard"
          :error="errors.euroEmissionsStandard"
          label="Норма таксичности"
        />
        <FormField
          v-model:value="values.co2EmissionsCity"
          name="co2EmissionsCity"
          type="number"
          :error="errors.co2EmissionsCity"
          label="Выбросы CO2, город, г/км"
        />
        <FormField
          v-model:value="values.co2EmissionsHighway"
          name="co2EmissionsHighway"
          type="number"
          :error="errors.co2EmissionsHighway"
          label="Выбросы CO2, трасса, г/км"
        />
        <FormField
          v-model:value="values.co2EmissionsMixed"
          name="co2EmissionsMixed"
          type="number"
          :error="errors.co2EmissionsMixed"
          label="Выбросы CO2, смешанный, г/км"
        />
      </div>

      <ToggleSection v-if="isElectric" label="Электро" :is-open="true">
        <div class="row-cols-4">
          <FormFieldSelect
            v-model:value="values.electricBatteryType"
            :options="BatteryTypeOptions"
            name="electricBatteryType"
            label="Тип батареи"
          />
          <FormField
            v-model:value="values.electricCapacity"
            name="electricCapacity"
            type="number"
            label="Емкость батареи, кВт*ч"
          />
          <FormField
            v-model:value="values.electricWltpShortage"
            name="electricWltpShortage"
            type="number"
            label="WLTP Расход до, кВтч/100 км"
          />
          <FormField
            v-model:value="values.electricWltpRange"
            name="electricWltpRange"
            type="number"
            label="Запас хода (WLTP до), км."
          />
        </div>
        <div class="row-cols-3">
          <FormField
            v-model:value="values.electricChargingTimeDC"
            name="electricChargingTimeDC"
            type="number"
            :error="errors.electricChargingTimeDC"
            label="Время зарядки постоянным током (DC), ч."
          />
          <FormField
            v-model:value="values.electricChargingTimeAC"
            name="electricChargingTimeAC"
            type="number"
            :error="errors.electricChargingTimeAC"
            label="Время зарядки переменным током (AC), ч."
          />
          <FormField
            v-model:value="values.electricEnginesCount"
            name="electricEnginesCount"
            type="number"
            :error="errors.electricEnginesCount"
            label="Кол-во электродвигателей"
          />
        </div>
      </ToggleSection>

      <hr />

      <FormFieldMultiSelect
        v-model:selected-options="values.complectations"
        :disabled="isComplectationsLoading"
        :options="complectationOptions"
        label="Комплектации"
        :searchable="false"
      />
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        :is-creation="isCreation"
        :can-create-another="true"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FormFieldSelect,
  FormFooter,
  ToggleSection,
  FormFieldMultiSelect,
  TagerFormSubmitEvent,
  getFilterParams,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import {
  convertEngineFormValuesToSavePayload,
  EngineFormValues,
  getEngineFormValues,
} from "@/modules/engines/EngineForm/EngineForm.helpers";
import { getBrandFormUrl, getGenerationFormUrl } from "@/utils/paths";
import {
  createEngine,
  getEngine,
  getEngineUpdateUrl,
  updateEngine,
} from "@/modules/engines";
import { getComplectationList, getGeneration } from "@/services/requests";
import {
  EngineType,
  EngineTypeOptions,
  GearboxOptions,
  EuroStandardOptions,
  BatteryTypeOptions,
  WheelOptions,
} from "@/enums";
import { ComplectationInterface } from "@/typings/model";

export default defineComponent({
  name: "EngineForm",
  components: {
    FormField,
    FormFieldSelect,
    Page,
    FormFooter,
    ToggleSection,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isSubmitting = ref<boolean>(false);
    const errors = ref<Record<string, string>>({});

    const id = computed(() => route.params.id as string | undefined);
    const isCreation = computed(() => id.value === "create");
    const queryGenerationId = computed(() => {
      return isCreation.value
        ? (route.query.generation as string | undefined)
        : undefined;
    });

    const generationId = computed<number | undefined>(() => {
      if (isCreation.value) {
        return queryGenerationId.value ? +queryGenerationId.value : undefined;
      } else {
        return model.value?.generation?.id || undefined;
      }
    });

    const values = ref<EngineFormValues>(getEngineFormValues(null));

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getEngine(+id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Engine",
    });

    const [
      fetchGeneration,
      { data: generation, loading: isGenerationLoading },
    ] = useResource({
      fetchResource: () => {
        if (generationId.value) {
          return getGeneration(+generationId.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Generation",
    });

    const [
      fetchComplectations,
      { data: complectations, loading: isComplectationsLoading },
    ] = useResource<Array<ComplectationInterface>>({
      fetchResource: () =>
        getComplectationList(
          getFilterParams({
            generation: generationId.value ?? 0,
          })
        ),
      initialValue: [],
      resourceName: "Generation Complectation List",
    });

    onMounted(() => {
      fetchModel();
      fetchComplectations();
    });

    watch(generationId, () => {
      fetchGeneration();
      fetchComplectations();
    });
    watch(id, fetchModel);
    watch(model, () => (values.value = getEngineFormValues(model.value)));

    function submitForm(event: TagerFormSubmitEvent) {
      if (!generationId.value) return;

      isSubmitting.value = true;

      const payload = convertEngineFormValuesToSavePayload(
        values.value,
        generationId.value
      );

      const requestPromise = isCreation.value
        ? createEngine(payload)
        : updateEngine(parseInt(id?.value || ""), payload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getEngineUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, backButtonUrl.value);
          }

          if (event.type === "create_create-another") {
            values.value = getEngineFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: isCreation.value
              ? "Двигатель успешно создан"
              : "Двигатель успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: isCreation.value
              ? "Ошибка добавления двигателя"
              : "Ошибка изменения двигателя",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => Boolean(isModelLoading.value) || Boolean(isGenerationLoading.value)
    );

    const pageTitle = computed<string>(() => {
      if (isContentLoading.value) {
        return "Загрузка...";
      } else if (isCreation.value) {
        return `Новый двигатель`;
      } else {
        return `Редактирование двигателя # ${model.value?.id}`;
      }
    });

    const backButtonUrl = computed<string>(() =>
      getGenerationFormUrl({
        generationId: String(generationId?.value),
      })
    );

    const isElectric = computed<boolean>(
      () => values.value.type?.value === EngineType.Electric
    );

    const breadcrumbs = computed<Array<{ url: string; label: string }>>(() => {
      if (!generation.value) {
        return [];
      }

      const result = [];

      if (generation.value.brand?.id) {
        result.push({
          url: getBrandFormUrl({ brandId: generation.value.brand.id }),
          label: generation.value.brand.name,
        });
      }

      result.push({
        url: getGenerationFormUrl({ generationId: generation.value.id }),
        label: generation.value.name,
      });

      return result;
    });

    const complectationOptions = computed(() => {
      return complectations.value.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    });

    return {
      isElectric,
      isComplectationsLoading,
      complectationOptions,
      submitForm,
      pageTitle,
      isContentLoading,
      model,

      backButtonUrl,
      isSubmitting,
      isCreation,

      errors,
      values,

      EngineTypeOptions,
      GearboxOptions,
      EuroStandardOptions,
      BatteryTypeOptions,
      WheelOptions,
      breadcrumbs,

      generation,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  display: flex;

  div {
    margin-right: 3rem;
  }
}

.row-inputs {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.breadcrumbs {
  display: flex;
  gap: 0.3rem;

  li {
    position: relative;
    &:not(:last-child) {
      &:after {
        content: "-->";
        margin-left: 0.3rem;
      }
    }
  }
}
</style>
