<template v-if="!isLoading">
  <div class="row-cols-2">
    <FormFieldMultiSelect
      v-model:selected-options="bodiesValue"
      label="Тип кузова"
      :options="bodiesOptions"
      name="body"
    />

    <FormFieldMultiSelect
      v-model:selected-options="colorsValue"
      label="Цвет"
      :options="colorOptions"
      name="color"
    />
  </div>

  <br />

  <div class="row-cols-2">
    <FieldValue
      label="Коробка передач"
      :value="
        model.params.gearbox
          ?.map(
            (item) =>
              gearboxOptions?.find((option) => option.value === item)?.label
          )
          .join(', ') || ''
      "
    />
    <FieldValue
      label="Привод"
      :value="
        model.params.wheel
          ?.map(
            (item) =>
              wheelOptions?.find((option) => option.value === item)?.label
          )
          .join(', ') || ''
      "
    />
    <FieldValue
      label="Двигатель"
      :value="
        model.params.fuel
          ?.map(
            (item) =>
              fuelOptions?.find((option) => option.value === item)?.label
          )
          .join(', ') || ''
      "
    />
    <FieldValue
      label="Объем двигателя"
      :value="`от ${model.params.volumeFrom} до ${model.params.volumeTo}`"
    />
    <FieldValue
      label="Количество мест"
      :value="`от ${model.params.seatsFrom} до ${model.params.seatsTo}`"
    />
  </div>

  <template v-if="model.params.fuel.includes('ELECTRIC')">
    <hr />
    <FieldValue
      label="Тип батареи"
      :value="
        model.params.battery
          ?.map(
            (item) =>
              batteryOptions?.find((option) => option.value === item)?.label
          )
          .join(', ') || ''
      "
    />
    <FieldValue
      label="Запас хода"
      :value="`от ${model.params.electricDistanceFrom} до ${model.params.electricDistanceTo}`"
    />
    <FieldValue
      label="Емкость батареи"
      :value="`от ${model.params.electricBatteryCapacityFrom} до ${model.params.electricBatteryCapacityTo}`"
    />
  </template>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";

import { FieldValue, OptionType, FormFieldMultiSelect } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { FullGenerationInterface } from "@/typings/model";
import {
  GenerationParamOption,
  getGenerationParamsOptions,
} from "@/services/requests";
import { TechnicalParams } from "@/modules/generations/GenerationForm/GenerationForm.helpers";

export default defineComponent({
  name: "GenerationFormTechnicalParamsValues",
  components: {
    FieldValue,
    FormFieldMultiSelect,
  },
  props: {
    model: {
      type: Object as PropType<FullGenerationInterface>,
      required: true,
    },
    value: {
      type: Object as PropType<TechnicalParams>,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, context) {
    const colorsValue = ref<Array<OptionType<number>>>([]);
    const bodiesValue = ref<Array<OptionType<string>>>([]);

    const [
      fetchParamsOptions,
      { loading: paramsOptionsLoading, data: paramsOptions },
    ] = useResource<GenerationParamOption[]>({
      fetchResource: () => getGenerationParamsOptions(),
      initialValue: [],
      resourceName: "Опции параметров",
    });

    onMounted(() => {
      fetchParamsOptions();
    });

    const bodiesOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "BODY")?.options ||
        []
    );

    const colorOptions = computed<Array<OptionType<number>>>(() => {
      const options =
        paramsOptions.value?.find((item) => item.param === "COLOR")?.options ||
        [];

      return options.map((item) => ({ ...item, value: +item.value }));
    });

    watch(bodiesOptions, () => {
      bodiesValue.value = bodiesOptions.value.filter((item) =>
        props.value.body.includes(item.value)
      );
    });

    watch(colorOptions, () => {
      colorsValue.value = colorOptions.value.filter((item) =>
        props.value.color.includes(item.value)
      );
    });

    const isLoading = computed<boolean>(() => paramsOptionsLoading.value);

    watch([colorsValue, bodiesValue], () => {
      context.emit("update:value", {
        color: colorsValue.value.map((item) => item.value),
        body: bodiesValue.value.map((item) => item.value),
      });
    });

    return {
      isLoading,
      bodiesOptions,
      colorOptions,

      colorsValue,
      bodiesValue,
    };
  },
});
</script>
