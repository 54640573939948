import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";

export type ExteriorLandingFormValues = {
  exteriorTitle?: string;
  exteriorText?: string;
  exteriorColorsTitle?: string;
  exteriorText2?: string;
};

export const convertExteriorValueToFormValues = (
  data: any
): ExteriorLandingFormValues => ({
  exteriorTitle: data.exteriorTitle,
  exteriorText: data.exteriorText,
  exteriorColorsTitle: data.exteriorColorsTitle,
  exteriorText2: data.exteriorText2,
});

export type ExteriorLandingValue = {
  exteriorTitle?: string;
  exteriorColorsTitle?: string;
  exteriorText?: string;
  exteriorText2?: string;
  exteriorGallery?: Array<{
    image: string | null;
    imageMobile: string | null;
    params: Array<{
      param: string;
      value: string;
    }>;
  }>;
  exteriorColors?: Array<{
    name: string;
    color: string;
    images: Array<{
      image: string | null;
      points: Array<{
        image: string | null;
        video: string | null;
        title: string;
        description: string;
        left: number;
        top: number;
      }>;
    }>;
  }>;

  exteriorFeatures?: Array<{
    name: string;
    image: string | null;
    video: string | null;
  }>;
};

export const convertExteriorFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  exteriorGalleryField: RepeaterField,
  exteriorColorsField: RepeaterField,
  exteriorFeaturesField: RepeaterField
): ExteriorLandingValue => ({
  exteriorTitle: formValues.exteriorTitle,
  exteriorText: formValues.exteriorText,
  exteriorColorsTitle: formValues.exteriorColorsTitle,
  exteriorGallery: exteriorGalleryField.value.map((item) => ({
    image: item.value[0].value ? (item.value[0].value as any).file.id : null,
    imageMobile: item.value[1].value
      ? (item.value[1].value as any).file.id
      : null,
    params: item.value[2].value
      ? (item.value[2].value as any[]).map((item: any) => ({
          param: String(item.value[0].value),
          value: String(item.value[1].value),
        }))
      : [],
  })),
  exteriorColors: exteriorColorsField.value.map((item) => ({
    name: String(item.value[0].value),
    color: String(item.value[1].value),
    images: item.value[2].value
      ? (item.value[2].value as any[]).map((item: any) => ({
          image: item.value[0].value
            ? (item.value[0].value as any).file.id
            : null,
          points: item.value[1].value
            ? (item.value[1].value as any[]).map((item: any) => ({
                image: item.value[0].value
                  ? (item.value[0].value as any).file.id
                  : null,
                video: item.value[1].value
                  ? (item.value[1].value as any).file.id
                  : null,
                title: String(item.value[2].value),
                description: String(item.value[3].value),
                left: +String(item.value[4].value),
                top: +String(item.value[5].value),
              }))
            : [],
        }))
      : [],
  })),
  exteriorText2: formValues.exteriorText2,
  exteriorFeatures: exteriorFeaturesField.value.map((item) => ({
    name: String(item.value[0].value),
    image: item.value[1].value ? (item.value[1].value as any).file.id : null,
    video: item.value[2].value ? (item.value[2].value as any).file.id : null,
  })),
});

export const ExteriorGalleryConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Галерея",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (1230 x 450)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "imageMobile",
      type: "IMAGE",
      label: "Картинка на телефон (420 x 560)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "params",
      type: "REPEATER",
      label: "Параметры",
      meta: {
        view: "TABLE",
        noToggle: true,
      },
      fields: [
        {
          name: "param",
          type: "STRING",
          label: "Параметр",
        },
        {
          name: "value",
          type: "STRING",
          label: "Значение",
        },
      ],
    },
  ],
};

export const createExteriorGalleryField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      ExteriorGalleryConfig,
      data.exteriorGallery?.map((item: any) => [
        {
          name: "image",
          value: item.image,
        },
        {
          name: "imageMobile",
          value: item.imageMobile,
        },
        {
          name: "params",
          value: item.params.map((item: any) => [
            {
              name: "param",
              value: item.param,
            },
            {
              name: "value",
              value: item.value,
            },
          ]),
        },
      ]) || []
    )
  );

export const ExteriorColorsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Цвета",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Название",
    },
    {
      name: "color",
      type: "COLOR",
      label: "Цвет",
    },
    {
      name: "images",
      type: "REPEATER",
      label: "Картинки",
      meta: {
        view: "BLOCK",
        noToggle: true,
      },
      fields: [
        {
          name: "image",
          type: "IMAGE",
          label: "Картинка (600 x 370)",
          meta: {
            scenario: "generation-landing",
          },
        },
        {
          name: "points",
          type: "REPEATER",
          label: "Точки",
          meta: {
            view: "BLOCK",
            noToggle: true,
          },
          fields: [
            {
              name: "image",
              type: "IMAGE",
              label: "Картинка (255 x 170)",
              meta: {
                scenario: "generation-landing",
              },
            },
            {
              name: "video",
              type: "FILE",
              label: "Видео",
              meta: {
                scenario: "generation-landing-video",
              },
            },
            {
              name: "title",
              type: "STRING",
              label: "Название",
            },
            {
              name: "description",
              type: "TEXT",
              label: "Описание",
            },
            {
              name: "left",
              type: "NUMBER",
              label: "X, %",
            },
            {
              name: "top",
              type: "NUMBER",
              label: "Y, %",
            },
          ],
        },
      ],
    },
  ],
};

export const createExteriorColorsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      ExteriorColorsConfig,
      data.exteriorColors?.map((item: any) => {
        return [
          {
            name: "name",
            value: item.name,
          },
          {
            name: "color",
            value: item.color,
          },
          {
            name: "images",
            value: item.images.map((item: any) => [
              {
                name: "image",
                value: item.image,
              },
              {
                name: "points",
                value: item.points.map((item: any) => [
                  {
                    name: "image",
                    value: item.image,
                  },
                  {
                    name: "video",
                    value: item.video,
                  },
                  {
                    name: "title",
                    value: item.title,
                  },
                  {
                    name: "description",
                    value: item.description,
                  },
                  {
                    name: "left",
                    value: item.left,
                  },
                  {
                    name: "top",
                    value: item.top,
                  },
                ]),
              },
            ]),
          },
        ];
      }) || []
    )
  );

export const ExteriorFeaturesConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Преимущества",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Название",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (390 x 260)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "video",
      type: "FILE",
      label: "Видео",
      meta: {
        scenario: "generation-landing-video",
      },
    },
  ],
};

export const createExteriorFeaturesField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      ExteriorFeaturesConfig,
      data.exteriorFeatures?.map((item: any) => [
        {
          name: "name",
          value: item.name,
        },
        {
          name: "image",
          value: item.image,
        },
        {
          name: "video",
          value: item.video,
        },
      ]) || []
    )
  );
