<template>
  <Page title="Экстерьер - Точки">
    <FormFieldFileInput
      v-if="!src"
      v-model:value="file"
      type="image"
      @change="onFileUpload"
    />
    <template v-else>
      <BaseButton @click="reset">Другая картинка</BaseButton>
      <div class="scene">
        <div id="scene" class="scene__inner" @click="onSceneClick">
          <img :src="src" />
          <div
            v-for="item in points"
            :key="item.x"
            class="scene__point"
            :style="{ left: item.x + '%', top: item.y + '%' }"
            @click="
              (e: PointerEvent) => {
                onPointClick(item.x, item.y);
                e.stopPropagation();
              }
            "
          >
            <span>{{ `${item.x}% / ${item.y}%` }}</span>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { Page } from "@tager/admin-layout";
import { FormFieldFileInput, BaseButton } from "@tager/admin-ui";

export default defineComponent({
  name: "ExteriorPoints",
  components: {
    Page,
    FormFieldFileInput,
    BaseButton,
  },
  setup() {
    const file = ref();
    const points = ref<Array<{ x: number; y: number }>>([]);

    const src = ref<string>("");

    const onFileUpload = () => {
      src.value = file.value?.file.url || null;
    };

    const onSceneClick = (e: PointerEvent) => {
      const sceneElement = document.getElementById("scene");
      if (!sceneElement) return;

      const { x, y, width, height } = sceneElement.getBoundingClientRect();

      const xValue = Math.round(((e.pageX - x) / width) * 100 * 100) / 100;
      const yValue = Math.round(((e.pageY - y) / height) * 100 * 100) / 100;

      points.value = [...points.value, { x: xValue, y: yValue }];
    };

    const onPointClick = (x: number, y: number) => {
      points.value = points.value.filter(
        (item) => item.x !== x || item.y !== y
      );
    };

    const reset = () => {
      file.value = null;
      src.value = "";
      points.value = [];
    };

    return {
      file,
      src,
      onFileUpload,
      onSceneClick,
      points,
      onPointClick,
      reset,
    };
  },
});
</script>

<style lang="scss" scoped>
.scene {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  &__inner {
    position: relative;
    background: #eee;
    cursor: pointer;
  }

  &__point {
    position: absolute;
    border-radius: 50%;
    background: red;
    border: 1px solid #000;
    cursor: pointer;
    width: 20px;
    height: 20px;

    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      top: -5px;
      background: #fff;
      border: 1px solid #000;
      color: #000;
      padding: 2px 5px;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}
</style>
