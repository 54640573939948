import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToggleSection, { label: "Баннер" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.bannerTitle,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValues.bannerTitle) = $event)),
          name: "name",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.bannerSubtitle,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValues.bannerSubtitle) = $event)),
          name: "subtitle",
          label: "Подзаголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.bannerDesktop,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValues.bannerDesktop) = $event)),
          label: "Баннер - Десктоп (1360 x 450)",
          name: "bannerDesktop",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.bannerMobile,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formValues.bannerMobile) = $event)),
          label: "Баннер - Телефон (375 x 450)",
          name: "bannerMobile",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.bannerVideo,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formValues.bannerVideo) = $event)),
          label: "Баннер - Видео",
          name: "bannerVideo",
          "file-type": "video",
          scenario: "generation-landing-video",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.bannerParamsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Основной блок" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.mainBlocksTitle,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formValues.mainBlocksTitle) = $event)),
          name: "mainBlocksTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.mainBlocksText,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formValues.mainBlocksText) = $event)),
          name: "mainBlocksText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.mainBlocksField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Экстерьер" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.exteriorTitle,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formValues.exteriorTitle) = $event)),
          name: "exteriorTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.exteriorText,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formValues.exteriorText) = $event)),
          name: "exteriorText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.exteriorGalleryField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.exteriorColorsTitle,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formValues.exteriorColorsTitle) = $event)),
          name: "exteriorColorsTitle",
          label: "Заголовок блока с цветами",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.exteriorColorsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.exteriorText2,
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formValues.exteriorText2) = $event)),
          name: "exteriorText2",
          label: "Текст 2",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.exteriorFeaturesField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Галерея" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.galleryTitle,
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formValues.galleryTitle) = $event)),
          name: "galleryTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.galleryText,
          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formValues.galleryText) = $event)),
          name: "galleryText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.galleryItemsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Интерьер" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.interiorTitle,
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formValues.interiorTitle) = $event)),
          name: "interiorTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.interiorText,
          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formValues.interiorText) = $event)),
          name: "interiorText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.interiorGalleryField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.interiorTitle2,
          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formValues.interiorTitle2) = $event)),
          name: "interiorTitle2",
          label: "Заголовок - 2",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.interiorFeaturesField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "О модели" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.aboutTitle,
          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formValues.aboutTitle) = $event)),
          name: "aboutTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.aboutText,
          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formValues.aboutText) = $event)),
          name: "aboutText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.aboutParamsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.aboutGalleryField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.aboutTextsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.aboutFeaturesField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Что говорят?" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.opinionsTitle,
          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formValues.opinionsTitle) = $event)),
          name: "opinionsTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.opinionsText,
          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formValues.opinionsText) = $event)),
          name: "opinionsText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.opinionsItemsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Фишки" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.plusesTitle,
          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.formValues.plusesTitle) = $event)),
          name: "plusesTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.plusesText,
          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.formValues.plusesText) = $event)),
          name: "plusesText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.plusesItemsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Безопасность" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.safetyTitle,
          "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formValues.safetyTitle) = $event)),
          name: "safetyTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.safetyText,
          "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.formValues.safetyText) = $event)),
          name: "safetyText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.safetyRatingTitle,
          "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.formValues.safetyRatingTitle) = $event)),
          name: "safetyRatingTitle",
          label: "Рейтинг - Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.safetyRatingValue,
          "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.formValues.safetyRatingValue) = $event)),
          name: "safetyRatingValue",
          type: "number",
          label: "Рейтинг - Кол-во звезд",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.safetyRatingLogos,
          "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.formValues.safetyRatingLogos) = $event)),
          label: "Рейтинг - Логотипы",
          name: "safetyRatingLogos",
          "file-type": "image",
          scenario: "generation-landing",
          multiple: "",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.safetyImage,
          "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.formValues.safetyImage) = $event)),
          label: "Картинка (1230 x 517)",
          name: "safetyImage",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.safetyImageMobile,
          "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.formValues.safetyImageMobile) = $event)),
          label: "Картинка мобильная (400 x 270)",
          name: "safetyImageMobile",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.safetyVideo,
          "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.formValues.safetyVideo) = $event)),
          label: "Видео - Файл",
          name: "safetyVideo",
          "file-type": "video",
          scenario: "generation-landing-video",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.safetyVideoUrl,
          "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.formValues.safetyVideoUrl) = $event)),
          name: "safetyVideoUrl",
          label: "Видео - Ссылка",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.safetyItemsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_ToggleSection, { label: "Гарантия" }, {
      default: _withCtx(() => [
        _createVNode(_component_FormField, {
          value: _ctx.formValues.warrantyTitle,
          "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.formValues.warrantyTitle) = $event)),
          name: "warrantyTitle",
          label: "Заголовок",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormField, {
          value: _ctx.formValues.warrantyText,
          "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.formValues.warrantyText) = $event)),
          name: "warrantyText",
          label: "Текст",
          type: "textarea",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.warrantyImage,
          "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.formValues.warrantyImage) = $event)),
          label: "Картинка (1230 x 450)",
          name: "warrantyImage",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.formValues.warrantyImageMobile,
          "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.formValues.warrantyImageMobile) = $event)),
          label: "Картинка мобильная (420 х 280)",
          name: "warrantyImageMobile",
          "file-type": "image",
          scenario: "generation-landing",
          onChange: _ctx.updateBackValues
        }, null, 8, ["value", "onChange"]),
        _createVNode(_component_DynamicField, {
          field: _ctx.warrantyItemsField,
          onChange: _ctx.updateBackValues
        }, null, 8, ["field", "onChange"])
      ]),
      _: 1
    })
  ], 64))
}