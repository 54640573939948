export const ROUTE_PATHS = {
  BRAND_LIST: "/brands",
  BRAND_FORM: "/brands/:brandId",
  MODEL_LIST: "/models",
  COMPLECTATION_LIST: "/complectations",
  COMPLECTATION_FORM: "/complectations/:id",
  PARAMS_CATEGORIES_LIST: "/param-categories",
  PARAMS_CATEGORIES_FORM: "/param-categories/:id",
  PARAMS_LIST: "/params/categories/:category/params",
  PARAMS_FORM: "/params/:id",
  ENGINE_FORM: "/engines/:id",
  GENERATION_LIST: "/generations",
  GENERATION_FORM: "/generations/:generationId",
  UTILS_EXTERIOR_POINTS: "/utils/exterior-points",
};
