import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ff554d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scene" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Экстерьер - Точки" }, {
    default: _withCtx(() => [
      (!_ctx.src)
        ? (_openBlock(), _createBlock(_component_FormFieldFileInput, {
            key: 0,
            value: _ctx.file,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
            type: "image",
            onChange: _ctx.onFileUpload
          }, null, 8, ["value", "onChange"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_BaseButton, { onClick: _ctx.reset }, {
              default: _withCtx(() => [
                _createTextVNode("Другая картинка")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                id: "scene",
                class: "scene__inner",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSceneClick && _ctx.onSceneClick(...args)))
              }, [
                _createElementVNode("img", { src: _ctx.src }, null, 8, _hoisted_2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.points, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.x,
                    class: "scene__point",
                    style: _normalizeStyle({ left: item.x + '%', top: item.y + '%' }),
                    onClick: 
              (e) => {
                _ctx.onPointClick(item.x, item.y);
                e.stopPropagation();
              }
            
                  }, [
                    _createElementVNode("span", null, _toDisplayString(`${item.x}% / ${item.y}%`), 1)
                  ], 12, _hoisted_3))
                }), 128))
              ])
            ])
          ], 64))
    ]),
    _: 1
  }))
}